<template>
  <div>
    <b-row style="margin-bottom: 10px;">
      <b-col sm="12" lg="6"> </b-col>
      <b-col sm="12" lg="6" style="text-align: right;">
        <b-button v-if="user && user.permissions.indexOf('worganization_organization_record') !== -1" size="sm" @click="f_openNewOrganizationModal()" variant="primary">+ yeni organizasyon</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="12">
        <b-form-input id="organization_search" v-model="d_searchText" placeholder="Filtrelemek için yazınız."></b-form-input>
      </b-col>
    </b-row>
    
    <div class="organizationsTable" style="margin-top: 30px; padding: 15px;">
      <b-row class="tableHeader">
        <b-col cols="2" class="headerCol">Kategori</b-col>
        <b-col cols="5" class="headerCol">Organizasyonun Adı</b-col>
        <b-col cols="5" class="headerCol">
          <b-row>
            <b-col cols="6">Yetkililer</b-col>
            <b-col cols="6" style="font-size: 14px; color: royalblue;">
              <b-form-checkbox v-model="d_showAllUsers">tümünü göster</b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div v-for="tag of d_tagsForTable.with_tags.tags_order" style="margin-bottom: 20px;">
        <b-row 
          v-if="f_searchTag(tag)"
          :key="tag" 
          class="tableRow"
          style="padding: 10px 0;"
        >
          
          <b-col
            cols="2" 
            :style="{ 'background-color': d_tagsForTable.with_tags.tags[tag].tag_color.val }" 
            class="colTag"
          >
            <strong>{{ d_tagsForTable.with_tags.tags[tag].tag_name.val }}</strong>
            <button class="addOrganizationWithTagButton" @click="f_openNewOrganizationModal(d_tagsForTable.with_tags.tags[tag])">+</button>
          </b-col>

          <b-col cols="10">
            <div :style="'box-shadow: -1px -1px 0px 0px #7e7d7d; border-radius: 0px 10px 10px 0px; margin-left: -17px; height: 20px; background-color: ' + d_tagsForTable.with_tags.tags[tag].tag_color.val"> </div>
            <b-row 
              v-for="organization of d_tagsForTable.with_tags.tags[tag].list" 
              :key="organization.id" 
              class="innerTableRow"
              style="padding: 3px 0; border-bottom: solid 1px #fde8fe; border-top: solid 1px #fde8fe;"
              v-if="f_searchOrganization(organization)"
            >
              <b-col cols="1" style="padding: 5px;">
                <i class="fa fa-edit editOrgButton" @click="f_editOrganizationUser(organization)"></i>
                <i class="fa fa-plus newOrgButton" @click="f_addNewOrganizationByCopying(organization)"></i>
              </b-col>
              <b-col cols="5">
                <span class="organizationButton" @click="f_goToThisOrganization(organization.id)" :title="organization.label">
                  {{ organization.label.length < 50 ? organization.label : organization.label.substring(0,50) + '...' }}
                  <small> [{{ organization.id }}]</small>
                </span>
              </b-col>

              <b-col cols="6">
                <div v-if="d_userOfOrganizations[organization.id]" :title="f_prepareUserListAsText(organization.id)">
                  <small v-for="(user_item, user_item_index) in d_userOfOrganizations[organization.id]" :key="user_item.w_id">
                    <template v-if="user_item_index < 3 || d_showAllUsers">
                      {{ user_item.label }}
                      <small v-if="user_item_index + 1 !== d_userOfOrganizations[organization.id].length">, </small>
                    </template>
                  </small>
                  <template v-if="!d_showAllUsers && d_userOfOrganizations[organization.id].length > 3">
                    ...
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="d_tagsForTable.without_tags.list.length &&  f_searchTag() " class="tableRow" style="padding: 10px 0;">
        <b-col cols="2" class="colTag" style="background-color: #f6f1f4;">Etiketsiz</b-col>
        <b-col cols="10">
          <div style="box-shadow: -1px -1px 0px 0px #7e7d7d; border-radius: 0px 10px 10px 0px; margin-left: -17px; height: 20px; background-color: #f6f1f4;"> </div>
          <b-row 
          v-for="organization of d_tagsForTable.without_tags.list" 
          v-if="f_searchOrganization(organization)" 
          :key="organization.id" 
          class="innerTableRow" 
          style="padding: 3px 0;">
            <b-col cols="1" style="padding: 5px;">
              <i class="fa fa-edit editOrgButton" @click="f_editOrganizationUser(organization)"></i>
              <i class="fa fa-plus newOrgButton" @click="f_addNewOrganizationByCopying(organization)"></i>
            </b-col>
            <b-col cols="5">
                <span class="organizationButton" @click="f_goToThisOrganization(organization.id)" :title="organization.label">
                  {{ organization.label.length < 50 ? organization.label : organization.label.substring(0,50) + '...' }}
                  <small> [{{ organization.id }}]</small>
                </span>
            </b-col>

            <b-col cols="6">
              <div v-if="d_userOfOrganizations[organization.id]" :title="f_prepareUserListAsText(organization.id)">
                <small v-for="(user_item, user_item_index) in d_userOfOrganizations[organization.id]" :key="user_item.w_id">
                  <template v-if="user_item_index < 3 || d_showAllUsers">
                    {{ user_item.label }}
                    <small v-if="user_item_index + 1 !== d_userOfOrganizations[organization.id].length">, </small>
                  </template>
                </small>
                <template v-if="!d_showAllUsers && d_userOfOrganizations[organization.id].length > 3">
                  ...
                </template>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <b-modal v-if="show_organization_modal" v-model="show_organization_modal" title="Organizasyon Kayıt" centered class="modal-success" @ok="show_organization_modal = false" ok-variant="success" hide-footer size="lg">
      <template v-for="(org, org_ind) in d_organizationRecordList">
        <b-card class="mb-1" no-body bg-variant="white" style="margin: 10px;">
          <b-card-header header-bg-variant="primary" size="sm" class="p-1">
            <b-row style="margin: 3px;">
              <b-col sm="12" lg="6">
                <strong>{{ org.id ? org.id + ' id li organizasyon güncellemesi ' : 'yeni organizasyon' }}</strong>
              </b-col>
              <b-col sm="12" lg="6" style="text-align: right;">
                <b-button @click="f_copyOrganizationItem(org_ind)" variant="white" size="sm">
                  <span style="color: white;">kopyala</span>
                </b-button>
                <b-button @click="f_deleteOrganizationItem(org_ind)" variant="warning" size="sm">
                  <span style="color: white;">sil</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>

          <b-row style="margin: 3px;">
            <b-col sm="12" md="3">
              <strong>Organizasyon Adı</strong>
            </b-col>
            <b-col sm="12" md="9">
              <b-textarea v-model="org.label" rows="3"></b-textarea>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" md="3">
              <strong>Organizasyon Hakkında</strong>
            </b-col>
            <b-col sm="12" md="9">
              <b-textarea v-model="org.about" rows="5"></b-textarea>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" md="3">
              <strong>Kullanıcılar</strong>
            </b-col>
            <b-col sm="12" md="9">
              <template v-for="(user_item, user_item_index) in org.user_list">
                <b-row style="margin: 3px;">
                  <b-col sm="12" md="9">
                    {{ user_item.label }} <small style="color: green;">[id: {{ user_item.id }}]</small>
                  </b-col>
                  <b-col sm="12" md="3">
                    <b-button @click="f_removeUser(org_ind, user_item_index)" variant="warning" size="sm">sil</b-button>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <b-card class="mb-1" no-body bg-variant="white" style="margin: 10px;">
            <b-card-header header-bg-variant="primary" size="sm" class="p-1">
              <strong>Kullanıcı Ara</strong>
            </b-card-header>
            <b-row style="margin: 3px;">
              <b-col sm="12" md="3">
                <strong>Kullanıcı Ara</strong>,
                <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" />
              </b-col>
              <b-col sm="12" md="9">
                <b-input-group>
                  <b-form-input type="search" v-on:search="f_searchInUsers" v-model="d_userSearch.text" placeholder="arama alanı" @keydown.enter.native="ClickEnter"></b-form-input>
                  <b-input-group-append>
                    <b-button size="lg" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_deleteSearchText()" title="temizle">
                      <img src="@/icon/3934901.png" style="width: 30px;" />
                    </b-button>
                    <b-button size="lg" class="pull-right" style="padding: 0px; width: 100px; border: solid 1px #20a2ce;" variant="white" @click="f_searchInUsers()" title="ara">
                      <img src="@/icon/9873.png" style="width: 30px;" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <template v-for="(search_user_item, search_user_item_index) in d_userSearch.search_list">
              <b-row style="margin: 3px;">
                <b-col sm="12" md="9">
                  {{ search_user_item.name }} <small style="color: blue;">[username: {{ search_user_item.username }}]</small> <small style="color: green;">[id: {{ search_user_item.id }}]</small>
                </b-col>
                <b-col sm="12" md="3">
                  <b-button @click="f_addToOrganizatinUsers(search_user_item, org_ind)" variant="success" size="sm">ekle</b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
          <b-card class="mb-1" no-body bg-variant="white" style="margin: 10px;">
            <b-card-header header-bg-variant="primary" size="sm" class="p-1">
              <strong>Etiket</strong>
            </b-card-header>
            <b-row style="margin-top: 20px;">
              <b-col cols="6" style="padding-left: 30px;" v-if="d_tagsForTable.with_tags.tags_order && d_tagsForTable.with_tags.tags_order.length" >
                <select class="modal-input" v-model="d_selectedOption" @change="handleSelect($event, org)">
                  <option disabled selected value="default">Seçim yapabilirsiniz</option>
                  <option v-for="tag, tagIndex of d_tagsForTable.with_tags.tags_order" :value="tagIndex" :key="tag">
                    {{ d_tagsForTable.with_tags.tags[tag].tag_name.val }}
                  </option>
                </select>
              </b-col>
              <b-col v-else>
                  <p style="padding-left: 30px;">Henüz bir etiketiniz yok</p>
              </b-col>
              <b-col cols="6">
                <div style="padding-right: 15px;">
                  <input type="color" value="#ffffff" style="width: 20%;" @change="f_updateColor($event)" class="colorInput" required>
                  <input v-model="d_name" type="text" class="nameInput" style="width: 80%" required><br/>
                 </div>
                <div style="display: flex; justify-content:center" >
                  <button class="addTagButton" @click="f_addTag(org)" >ekle</button>
                </div>                  
              </b-col>
            </b-row>
            <b-row class="selectedTagRow" v-if="org.tag && org.tag.list && org.tag.list[0] && org.tag.list[0].tag_name && org.tag.list[0].tag_name.val " style=" margin-top: 15px" >
              <b-col cols="6" >
                <div class="colorDiv" :style='{ "background-color": org.tag.list[0].tag_color.val }' ></div>   
              </b-col>
              <b-col cols="6" >
                <p style="margin-left: 5px; max-width: 80%">{{org.tag.list[0].tag_name.val}}</p>
              </b-col>
            </b-row>
            <b-row v-else style="display: flex; justify-content: center; margin-top: 15px">
               <p style="color: red;">Lütfen etiket seçiniz !!!</p>
            </b-row>
          </b-card>
        </b-card>
      </template>
      <b-row>
        <b-col cols="8"> </b-col>
        <b-col cols="2">
          <b-button style="width: 100%;" variant="outline-danger" @click="show_organization_modal = false">kapat</b-button>
        </b-col>
        <b-col cols="2">
          <b-button style="width: 100%;" variant="outline-success" :disabled="d_buttonDisabled" @click="f_organizationRecord()">kaydet</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { WmanagerApi } from '@/services/Wmanager/WmanagerApi';
import WdmService from '@/services/wdm';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import OrganizationService from '@/services/organization';
import moment from 'moment';
export default {
  name: 'OrganizationList',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
  }),
  components: {
    vSelect,
    Modal
  },
  data () {
    return {
      d_showAllUsers: false,
      d_loading: {
        'status': false,
      },
      d_hoverOrganizationIndex: '',
      d_userSearch: { 'text': '', 'search_list': [], 'search_count': 0 },
      d_organizationRecordList: [],
      d_searchText: '',
      d_WmanagerLayerData: require('@/services/Wmanager/Layers').Layers,
      d_selectedOrganizationIndex: '',
      d_organization: {
        'id': 'new',
        'name': '',
        'about': '',
        'list': [],
        'tree_detail': { 'label': 'Kullanıcı Bilgileri' },
        'tree_name': { 'label': 'Kullanıcı Listesi' },
        'type': 'organization'
      },
      show_organization_modal: false,
      d_organizationList: [],
      user: {},
      d_wdm22: {},
      d_defaultTag: { "tag_name": { "val": "" }, "tag_color": { "val": "#ffffff" } },

      d_tagsForTable : {
       "with_tags": { "tags_order": [], "tags": {} },
       "without_tags": { "list": [] }
      },
      d_userOfOrganizations: {},
      d_color: "#ffffff",
      d_name: "",
      d_buttonDisabled: true,
      d_selectedOption:"default"

    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getOrganizationList();
  },
  beforeMount () {},
  mounted: function () {
    setTimeout(function () {
      let doc = document.getElementById('organization_search');
      doc.focus();
    }, 500);
  },
  methods: {
    f_prepareUserListAsText: function (organization_id) {
      let user_text = '';
      if (this.d_userOfOrganizations[organization_id]) {
        for (let user_item of this.d_userOfOrganizations[organization_id]) {
          if (user_item.label) {
            user_text += user_item.label + ', ';
          }
        }
      }
      return user_text;
    },
    f_removeUser: function (org_ind, user_item_index) {
      this.d_organizationRecordList[org_ind].user_list.splice(user_item_index, 1);
      this.$forceUpdate();
    },
    f_getUserOfOrganizations: function () {
      OrganizationService.organization_user_list_get()
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.d_userOfOrganizations = resp.data.list;
          } else {
            alert('error: ' + resp.data.status_message);
          }
        });
    },
    f_editOrganizationUser: function (org_data) {
      this.d_name="";
      this.d_color="#ffffff";
      this.d_buttonDisabled = true;
      this.d_organizationRecordList = [];
      this.d_selectedOption="default"
      let new_organization_item = {};
      let organization_id;
      organization_id = org_data.id;
      new_organization_item = {
        'about': '',
        'id': organization_id,
        'label': org_data.label,
        'user_list': [],
      };
      // console.log(new_organization_item)
      let layer_type = 'organization';
      let w_id_list = [];
      w_id_list.push(organization_id);

      WmanagerApi.get_wdmr_list_by_wdmr_id(layer_type, w_id_list, 1)
        .then(resp => {
          // console.log(resp)
          let organization_data = resp.list[0].wdmr;
          let about = '';
          try {
            about = organization_data.data.general.about.val;
          } catch (err) {}
          new_organization_item.about = about;
          let layer_type = 'organization_users';
          let w_id_list = [];
          w_id_list.push(organization_id);
          let tag = []
          try {
            tag = organization_data.data.general.tag;
            new_organization_item.tag = tag;
            if(tag){
              this.d_buttonDisabled=false;
              let tag = organization_data.data.general.tag.list[0].tag_name.val;
              let tagIndex = this.d_tagsForTable.with_tags.tags_order.indexOf(tag);
              this.d_selectedOption = tagIndex
            }
          } catch (err) {}
          WmanagerApi.get_wdmr_list_by_wdmr_id(layer_type, w_id_list, 0)
            .then(resp => {
              // console.log(resp)
              let uniq_user_list = [];
              for (let u in resp.list) {
                if (uniq_user_list.indexOf(resp.list[u].w_id) === -1) {
                  uniq_user_list.push(resp.list[u].w_id);
                  let new_user_item = {
                    'id': resp.list[u].w_id,
                    'label': resp.list[u].label,
                  };
                  new_organization_item.user_list.push(new_user_item);
                }
              }
              this.d_organizationRecordList.push(new_organization_item);
              this.show_organization_modal = true;
            }, resp => {
              console.log(resp);
            });
        }, resp => {
          console.log(resp);
        });
    },
    f_addNewOrganizationByCopying: function (org_data) {
      this.d_name="";
      this.d_color="#ffffff";
      this.d_buttonDisabled = true;
      this.d_organizationRecordList = [];
      this.d_selectedOption="default"
      let new_organization_item = {};
      let organization_id;
      organization_id = org_data.id;
      new_organization_item = {
        'about': '',
        'id': '',
        'label': org_data.label,
        'user_list': [],
      };
      // console.log(new_organization_item)
      let layer_type = 'organization';
      let w_id_list = [];
      w_id_list.push(organization_id);

      WmanagerApi.get_wdmr_list_by_wdmr_id(layer_type, w_id_list, 1)
        .then(resp => {
          // console.log(resp)
          let organization_data = resp.list[0].wdmr;
          let about = '';
          try {
            about = organization_data.data.general.about.val;
          } catch (err) {}
          new_organization_item.about = about;
          let layer_type = 'organization_users';
          let w_id_list = [];
          w_id_list.push(organization_id);
          let tag = []
          try {
            tag = organization_data.data.general.tag;
            new_organization_item.tag = tag;
            if(tag){
              this.d_buttonDisabled=false;
              let tag = organization_data.data.general.tag.list[0].tag_name.val;
              let tagIndex = this.d_tagsForTable.with_tags.tags_order.indexOf(tag);
              this.d_selectedOption = tagIndex
            }
          } catch (err) {}
          WmanagerApi.get_wdmr_list_by_wdmr_id(layer_type, w_id_list, 0)
            .then(resp => {
              // console.log(resp)
              let uniq_user_list = [];
              for (let u in resp.list) {
                if (uniq_user_list.indexOf(resp.list[u].w_id) === -1) {
                  uniq_user_list.push(resp.list[u].w_id);
                  let new_user_item = {
                    'id': resp.list[u].w_id,
                    'label': resp.list[u].label,
                  };
                  new_organization_item.user_list.push(new_user_item);
                }
              }
              this.d_organizationRecordList.push(new_organization_item);
              this.show_organization_modal = true;
            }, resp => {
              console.log(resp);
            });
        }, resp => {
          console.log(resp);
        });
    },
    f_addToOrganizatinUsers: function (search_user_item, org_index) {
      let det = false;
      for (let i in this.d_organizationRecordList[org_index].user_list) {
        if (this.d_organizationRecordList[org_index].user_list[i].id === search_user_item.id) {
          det = true;
          break;
        }
      }
      if (!det) {
        let new_user_item = {
          'id': search_user_item.id,
          'label': search_user_item.name,
        };
        this.d_organizationRecordList[org_index].user_list.push(new_user_item);
      }
      this.$forceUpdate();
    },
    f_copyOrganizationItem: function (org_index) {
      let new_org_item = {};
      new_org_item.label = this.d_organizationRecordList[org_index].label;
      new_org_item.about = this.d_organizationRecordList[org_index].about;
      if(this.d_organizationRecordList[org_index].tag && this.d_organizationRecordList[org_index].tag.list && this.d_organizationRecordList[org_index].tag.list.length){
        new_org_item.tag = this.d_organizationRecordList[org_index].tag;
      } else{
        new_org_item.tag =  {"list": this.d_defaultTag } ;
      }
      new_org_item.user_list = this.d_organizationRecordList[org_index].user_list;
      this.d_organizationRecordList.push(JSON.parse(JSON.stringify(new_org_item)));
      this.$forceUpdate();
    },
    f_deleteOrganizationItem: function (org_index) {
      this.d_organizationRecordList.splice(org_index, 1);
      this.$forceUpdate();
    },
    f_deleteSearchText: function () {
      this.d_userSearch.text = '';
      this.d_userSearch.search_list = [];
      this.$forceUpdate();
    },
    ClickEnter: function () {
      // console.log('event.which ', event.which);
      if (event.which === 13) {
        this.f_searchInUsers();
      }
    },
    f_searchInUsers: function () {
      this.d_loading.status = true;
      if (this.d_userSearch.text) {
        this.d_userSearch.search_list = [];
        this.d_userSearch.search_count = 0;
        let data = {
          'search_text': this.d_userSearch.text
        };
        OrganizationService.user_search(data)
          .then(resp => {
            this.d_loading.status = false;
            if (resp.data.status_code === '12000') {
              this.d_userSearch.search_list = resp.data.user_list;
              this.d_userSearch.search_count = resp.data.user_count;
            } else {
              alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
            }
          });
      }
    },
    f_organizationRecord: function () {
      if (this.d_organizationRecordList.length > 0) {
        let data = {
          'organization_list': this.d_organizationRecordList
        };
        // console.log("data")
        // console.log(data)

        this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        OrganizationService.organization_record(data)
          .then(resp => {
            this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '12000') {
              this.show_organization_modal = false;
              this.f_getOrganizationList();
            } else {
              alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
            }
          });
      }
    },
    f_openNewOrganizationModal: function (tag) {
      this.d_organizationRecordList = [];
      this.d_buttonDisabled = true;
      this.d_color = "#ffffff";
      this.d_name = "";
      this.d_selectedOption="default"
      let new_organization = {
        'label': '',
        'about': '',
        'user_list': [],
        'tag': {"list": []}
      };
      if(tag){
        let tagColor = tag.tag_color.val;
        let tagName = tag.tag_name.val;
        new_organization.tag.list.push( {"tag_name": {"val": tagName}, "tag_color": {"val": tagColor}});
        this.d_buttonDisabled = false;
        let tagIndex = this.d_tagsForTable.with_tags.tags_order.indexOf(tag.tag_name.val);
        this.d_selectedOption=tagIndex
      }
      else{
        new_organization.tag.list.push( { "tag_name": { "val": "" }, "tag_color": { "val": "#ffffff" } } );
      }
      this.d_organizationRecordList.push(new_organization);
      this.show_organization_modal = true;
    },

    f_searchTag: function (tag) {
      if(tag){
        for(let org of this.d_tagsForTable.with_tags.tags[tag].list){
          if(this.f_searchOrganization(org)){
            return true;
          }
        }
      }
      else{
        for(let org of this.d_tagsForTable.without_tags.list){
          if(this.f_searchOrganization(org)){
            return true;
          }
        }
      }
      return false;;
    },

    f_searchOrganization: function (org) {
      let res = false;
      if (this.d_searchText) {
        if (org.label.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1 || (org.w_id &&  org.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) || (org.id &&  org.id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) ) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_getOrganizationList: function () {
      this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyonlar getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_list()
        .then(resp => {
          // console.log(resp)
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            this.d_tagsForTable = {
              "with_tags": { "tags_order": [], "tags": {} },
              "without_tags": { "list": [] }
            };
            this.d_organizationList = resp.data.list;
            for(let organization of this.d_organizationList) {
              if(organization.wdmr && organization.wdmr.data && organization.wdmr.data.general){
                if(organization.wdmr.data.general.tag && organization.wdmr.data.general.tag.list && organization.wdmr.data.general.tag.list.length) {
                  let tagName = organization.wdmr.data.general.tag.list[0].tag_name.val;
                  let tagColor = organization.wdmr.data.general.tag.list[0].tag_color.val;
                  if(!this.d_tagsForTable.with_tags.tags_order.includes(tagName)){
                    this.d_tagsForTable.with_tags.tags_order.push(tagName);
                    this.d_tagsForTable.with_tags.tags[tagName] = {};
                    this.d_tagsForTable.with_tags.tags[tagName].tag_name = {"val": tagName};
                    this.d_tagsForTable.with_tags.tags[tagName].tag_color = {"val": tagColor};
                    this.d_tagsForTable.with_tags.tags[tagName].list = [];
                  }
                  this.d_tagsForTable.with_tags.tags[tagName].list.push(organization.wdmr);
                }
                else {
                  this.d_tagsForTable.without_tags.list.push(organization.wdmr);
                }
              }
            }
            this.d_tagsForTable.with_tags.tags_order.sort((a, b) => a.localeCompare(b));
          } else {
            alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
          }
        });

      this.f_getUserOfOrganizations();

    },
    f_goToThisOrganization: function (wdmr307_id) {
      this.$router.push({ path: '/operations', query: { 'organization_id': wdmr307_id } });
    },
    handleSelect(event, org) {
      let index = event.target.value;
      // console.log(index)
      let tagsOrder = this.d_tagsForTable.with_tags.tags_order;

      if(!(org.tag && org.tag.list)){
        org.tag = { "list": [ this.d_defaultTag ] };
      }
      this.d_name = this.d_tagsForTable.with_tags.tags[tagsOrder[index]].tag_name.val;
      this.d_color = this.d_tagsForTable.with_tags.tags[tagsOrder[index]].tag_color.val;
      org.tag.list[0].tag_name.val = this.d_tagsForTable.with_tags.tags[tagsOrder[index]].tag_name.val;
      org.tag.list[0].tag_color.val = this.d_tagsForTable.with_tags.tags[tagsOrder[index]].tag_color.val;
      this.d_buttonDisabled = false;
      let colorInputElement = document.querySelector('.colorInput');
      colorInputElement.value = this.d_tagsForTable.with_tags.tags[tagsOrder[index]].tag_color.val;
    },
    f_addTag: function(org) {
      if(this.d_tagsForTable.with_tags.tags_order.includes(this.d_name)){
        alert("aynı isimde farklı renkte tag mevcut")
      }
      else{
        if(!(org.tag && org.tag.list)){
          org.tag = { "list": [ this.d_defaultTag ] };
        }
        org.tag.list[0].tag_name.val = this.d_name;
        org.tag.list[0].tag_color.val = this.d_color;
      }
      if(this.d_name && this.d_color){
        this.d_buttonDisabled = false;
        this.d_selectedOption = "default"
      }
    },
    f_updateColor: function(event){
      this.d_color = event.target.value;
    },
  },
  watch: {
  }
};

</script>

<style>
.organization_list_item: {
  height: 100%;
  cursor: pointer;
}

.nameInput{
  height: 30px;
}

.colorInput{
  height: 30px;
  padding: none !important;
}

.addTagButton {
  height: 30px;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ededed;
  color: black;
  transition: 0.3s ease;
}

.addTagButton:hover {
  background-color: #dbdbdb;

}

.tableHeader {
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 10px 0px;
}

.tableRow {
  /*border-bottom: 1px solid #ccc;*/
}

.innerTableRow {
  padding-left: 15px;
}

.headerCol {
  padding-bottom: 10px;
}

.addOrganizationWithTagButton{
  float: right;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: 1px solid grey;
  border-radius: 50%;
  transition: .3s ease;
  font-weight: bold;
}

.addOrganizationWithTagButton:hover{
  background-color: #FC0099;
}

.colTag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 0px 10px 10px;
  box-shadow: -1px -1px 3px 0px #7e7d7d;
  z-index: 1000;
}

.organizationCol.col-8{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.organizationButton{
  cursor:pointer;
  transition: .3s ease; 
  max-width: calc(100% - 30px);
}

.organizationButton:hover{
  color:#FC0099;
}

.colorDiv{
  height: 30px;
  width: 30px;
  margin-right: 5px;
  border: 1px solid black;
  float: right;
}
.selectedTagRow div.col-6{
  padding: 0px;
}

.editOrgButton{
  padding: 1px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: blueviolet;
}

.editOrgButton:hover {
  padding: 1px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: blueviolet;
  background-color: #fbd4eb;
  box-shadow: 1px 1px 5px 1px;
  border-radius: 3px 3px;
}

.newOrgButton{
  float: right;
  padding: 1px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: green;
}

.newOrgButton:hover {
  float: right;
  padding: 1px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: green;
  background-color: #d4fbf2;
  box-shadow: 1px 1px 5px 1px;
  border-radius: 3px 3px;
}


/*.organization_list_item:hover {
  height: 100%;
  background: #85FFBD;
}
*/

</style>


